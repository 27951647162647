<script setup lang="ts">
const { closeDialog, openDialog } = useDialog()
const { cookies, acceptAll, rejectAll, save, toggleCookie } =
  useOneTrustCookies()
const { dispatchCookieBannerEvent } = useGANavigationEvents()

const modalId = 'modal-cookie-banner'
const cookieBannerCenter = 'modal-cookie-banner-center'

const openCookieSettings = () => {
  dispatchCookieBannerEvent('preferences')
  openDialog(cookieBannerCenter)
}

const continueWithoutAcceptingCookies = () => {
  rejectAll()
  dispatchCookieBannerEvent('deny_all')
  closeDialog(modalId)
}

const acceptAllCookies = () => {
  acceptAll()
  dispatchCookieBannerEvent('accept_all')
  closeDialog(modalId)
}

const saveAllowedCookies = () => {
  save()
  dispatchCookieBannerEvent('preferences_saved')
  closeDialog(modalId)
}
const isOpen = ref(false)

setTimeout(() => {
  isOpen.value = true
}, 2000)
</script>
<template>
  <OrganismsModal
    :id="modalId"
    hide-close-button
    auto-height
    position="center"
    with-overlay
    class="z-[100] transition-opacity duration-500 ease-in-out"
    :class="{ 'opacity-100': isOpen, 'opacity-0': !isOpen }"
    :force-global-theme="true"
    @wheel.stop="''"
  >
    <template #body>
      <div class="bg-primitives-white">
        <div class="mb-6 flex justify-end">
          <AtomsCta
            anatomy="link"
            :icon-show-default="false"
            class="text-link-7"
            @click="continueWithoutAcceptingCookies()"
          >
            <template #label>
              {{ $ts('cookieBanner.continueWithoutAccepting') }}
            </template>
          </AtomsCta>
        </div>
        <p class="text-light-6 mb-6">
          {{ $ts('cookieBanner.description') }}
        </p>
        <div
          class="flex flex-col-reverse items-center justify-center gap-6 md:flex-row"
        >
          <AtomsCta anatomy="link" @click="openCookieSettings()">
            <template #label>{{ $ts('cookieBanner.settings') }}</template>
          </AtomsCta>
          <AtomsCta @click="acceptAllCookies()">
            <template #label>{{ $ts('cookieBanner.acceptAll') }}</template>
          </AtomsCta>
        </div>
      </div>
      <CookieBannerCenter
        :cookies
        @on-accept-all="acceptAllCookies"
        @on-save="saveAllowedCookies"
        @on-toggle-cookie="toggleCookie"
      />
    </template>
  </OrganismsModal>
</template>

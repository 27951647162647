import { createCommerceLayerClient } from '@integration-layer/configs/commercelayer'

export const useCL = () => {
  const { loggedIn, user } = useUserSession()

  const auth =
    import.meta.client && loggedIn.value
      ? useClCustomerAuth(user.value!.email)
      : useClAuth()

  const cl = createCommerceLayerClient(auth)

  return { cl }
}

type HeaderTransparencyColor = 'white' | 'black'

type HeaderTransparencyState = {
  active: boolean
  activeOnlyOnDesktop?: boolean
  color?: HeaderTransparencyColor
  paused?: boolean
}

export const useDefaultHeaderConfig = () => {
  const { isBrandHome, isPlp, isPdp, isEditorialPage } = useRouteHelper()
  const { y } = useWindowScroll()

  const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)

  const transparencyState = useState<HeaderTransparencyState>(
    'header-transparency',
    () => ({
      active: false,
    })
  )
  const megalogo = useState<boolean>('header-megalogo', () => false)
  const isPinned = useState<boolean>('header-pinned', () => false)
  const isNavigating = useState<boolean>('header-navigating', () => false)
  const freezeAnimation = useState<boolean>(
    'header-freeze-animation',
    () => false
  )

  const isWatcherAlreadyAdded = useState<boolean>(
    'header-watcher-already-set',
    () => false
  )

  const setHeaderTransparencyStatus = (
    active: boolean,
    onlyOnDesktop?: boolean
  ) => {
    transparencyState.value.active = active
    transparencyState.value.activeOnlyOnDesktop = !!onlyOnDesktop
  }

  const setHeaderTransparencyColor = (color: HeaderTransparencyColor) => {
    transparencyState.value.color = color
  }

  const setHeaderMegalogo = (isActive: boolean) => {
    megalogo.value = isActive
  }

  const setIsPinned = (pinned: boolean) => {
    isPinned.value = pinned
  }

  if (process.client && !isWatcherAlreadyAdded.value) {
    isWatcherAlreadyAdded.value = true

    /*
     * Is Loading
     * this reference allows us to freeze the component state and update it once the page has finished loading
     */
    const nuxt = useNuxtApp()
    nuxt.hook('page:loading:start', () => {
      isNavigating.value = true
      freezeAnimation.value = true
    })

    nuxt.hook('page:finish', () => {
      isPinned.value = false
      isNavigating.value = false
    })

    nuxt.hook('page:loading:end', () => {
      const scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop

      isPinned.value = scrollTop > removePx(stripMaxHeight.value)

      setTimeout(() => {
        freezeAnimation.value = false
      }, 100)
    })

    /**
     * Pause header transparency based on the current page and scroll position
     */

    //BRAND HOMEPAGE: observe the CSS variable '--header-strip-wrapper'
    const stripMaxHeight = useCssVar('--header-strip-wrapper', null, {
      observe: true,
    })

    //PLP: get the height of the 'plp-hero'
    const plpHeroHeight = ref(0)
    const plpHeroStop = ref()
    const headerDefaultHeight = useCssVar('--header-default-height', null, {
      observe: true,
    })
    //set up a watcher to get the plp-hero element when navigating from a page where it does not exist
    watch(
      () => [isNavigating.value, isPlp.value],
      ([loading, plpPage]) => {
        if (!loading) {
          if (plpPage) {
            const { height, stop } = useElementSize(
              document.getElementById('plp-hero')
            )
            plpHeroStop.value = stop
            watchEffect(
              () =>
                (plpHeroHeight.value =
                  height.value +
                  removePx(stripMaxHeight.value) -
                  removePx(headerDefaultHeight.value))
            )
          } else {
            if (typeof plpHeroStop.value === 'function') plpHeroStop.value()
            plpHeroHeight.value = 0
          }
        }
      },
      {
        immediate: true,
      }
    )

    //PDP: calculate the distance of the last slide in the product image gallery
    const pdpLastSlideOffset = ref(0)
    const calcDistanceOfLastSlide = () => {
      const el = document.querySelector(
        '#pdp-main-block .gallery-slider .swiper .swiper-slide:last-of-type'
      )
      if (el) {
        pdpLastSlideOffset.value = el.getBoundingClientRect().top
      }
    }
    //set up a watcher to add or remove the event listener when navigating
    watch(
      () => isPdp.value,
      pdpPage => {
        if (pdpPage) {
          calcDistanceOfLastSlide()
          useEventListener('scroll', calcDistanceOfLastSlide, {
            passive: true,
          })
        } else {
          removeEventListener('scroll', calcDistanceOfLastSlide)
        }
      },
      {
        immediate: true,
      }
    )

    //put together the logic for BRAND HOMEPAGE, PLP and PDP
    watchEffect(() => {
      // TODO: (new heder) remove the sticky from the header when we are on the success page
      if (!isNavigating.value) {
        if (isFederatedApp.value) {
          isPinned.value = y.value > removePx(stripMaxHeight.value)
        } else if (isBrandHome.value || isEditorialPage.value) {
          const isSticky =
            y.value > removePx(stripMaxHeight.value) || isPinned.value

          transparencyState.value.paused = isSticky
        } else if (isPlp.value) {
          transparencyState.value.paused =
            y.value > Math.round(plpHeroHeight.value)
        } else if (isPdp.value) {
          transparencyState.value.paused = pdpLastSlideOffset.value <= 0
        }
      }
    })
  }

  return {
    isNavigating,
    isPinned,
    freezeAnimation,
    transparencyState,
    megalogo,
    setHeaderMegalogo,
    setIsPinned,
    setHeaderTransparencyStatus,
    setHeaderTransparencyColor,
  }
}

/* eslint-disable camelcase */
import type {
  LineItemCreate,
  Sku,
  LineItem,
  AddressCreate,
  AddressUpdate,
  AdyenPaymentUpdate,
  PaypalPaymentCreate,
  PaypalPaymentUpdate,
  OrderUpdate,
} from '@commercelayer/sdk'
import { productSchema as algoliaProductSchema } from '@integration-layer/.nuxt/armani/product-schema'
import { z } from 'zod'
import { SIDEBAR_IDS } from '../../design-system/data/sidebarIds'

type MyLineItemCreate = Omit<LineItemCreate, 'quantity' | 'order'> & {
  // metadata: LineItemMetadata
}

export type LineItemMetadata = {
  product_image: any
  slug: string
  unitType: string
  expirationDate: string
  isDeductable: boolean
  price: number
  oldPrice: number
  ga4Item: any
}

export type PackagingAndGiftMetadata = {
  is_gift: boolean
  gift_message: string | undefined
  gift_email: string | undefined
  gift_shopping_bag: boolean
}

export type JapanDeliveryMetadata = {
  preferred_delivery_date: string
  preferred_delivery_hour: string
}

export type OrderMetadata = PackagingAndGiftMetadata

export type LineItem_<Item extends LineItem['item']> = LineItem & {
  item: Item
}

export type LineItemWithAlgoliaData = LineItem_<Sku> & {
  objectID: string
  Name: string
  Brand: string
  path: string
  assets: { URL: string; priority?: number }[]
  hierarchicalCategories: Record<string, string[]>
  sizes: {
    label?: string | undefined
    sizeCode?: string | undefined
    SKU?: string | undefined
    avEcom?: number | undefined
    avRet?: number | undefined
    Dims?: string
  }[]
  ColorDesc: string
  categoryPageId2: string[]
  MFC: string
}

export default () => {
  const { $cl } = useNuxtApp()
  const route = useRoute()
  const { cartUrl, checkoutUrl } = useRouteHelper()
  const { loggedIn, user } = useUserSession()
  const { userMeta, initUserMeta } = useUserMeta()
  const { pdpPath } = useRouteHelper()
  const { loadAlgoliaProducts } = useAlgoliaProducts()
  const { openDialog } = useDialog(SIDEBAR_IDS.headerSideCart)
  const isFederatedApp = computed(
    () => useRuntimeConfig().public.isFederated ?? false
  )

  const appConfig = useAppConfig()
  const configs = useConfigs()
  const { addSnackbar } = useHeaderSnackbars()
  const marketCode = computed(() => configs.value.cl_market_code)
  const currentMarketSettings = computed(() => appConfig.currentMarketSettings)

  const cartInitialized = useState(`cart-initialized`, () => false)
  const lastAdded = useState<LineItem_<Sku>>(`last-added`)
  const lineItemsQtyUpdating = reactive<Record<string, boolean>>({})
  const federatedCookieCartLineItemsCount: Ref<number> = useCookie(
    `federatedCookieCartLineItemsCount_${marketCode.value}`,
    { default: () => ref(0), domain: '.armani.com' }
  )
  const { setCartEventsPayload } = useGACartEvents()

  const fetchedAlgoliaProducts = useState<Set<string>>(() => new Set<string>())

  const algoliaProducts =
    useState<Record<string, z.infer<typeof algoliaProductSchema>>>(
      `algolia-products`
    )

  const fetchAlgoliaProducts = async () => {
    const skuCodes = cart.lineItems.value.map(
      element => getSkuWithoutSize(element.sku_code)!
    )
    const skusToFetch = skuCodes.filter(
      skuCode => !fetchedAlgoliaProducts.value.has(getSkuWithoutSize(skuCode)!)
    )
    if (skusToFetch.length) {
      fetchedAlgoliaProducts.value = new Set([
        ...fetchedAlgoliaProducts.value,
        ...skusToFetch,
      ])
      const newAlgoliaProducts = await loadAlgoliaProducts(skusToFetch)
      algoliaProducts.value = {
        ...algoliaProducts.value,
        ...newAlgoliaProducts,
      }
    }
  }

  const abandonedCartStorageKeyName = computed(
    () => `abandoned-cart-${marketCode.value}-checked`
  )

  const abandonedCartChecked = useLocalStorage(
    abandonedCartStorageKeyName.value,
    false
  )

  const abandonedGuestCartChecked = useSessionStorage(
    abandonedCartStorageKeyName.value,
    false
  )

  const BASE_CART_COOKIE_NAME = 'cl_cart_order_id'
  const guestCartIdCookieName = computed(
    () => `${BASE_CART_COOKIE_NAME}market:${marketCode.value}`
  )

  // CART
  const cart = useOrder('cart')

  // const shippingInfos = useShippingInfos(cart.order)

  // COOKIES
  const saveGuestCartId = () => {
    try {
      if (cart?.order?.value?.id && !loggedIn.value) {
        const cartCookie = useCookie(guestCartIdCookieName.value, {
          expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000), // 1 year
        })
        cartCookie.value = cart.order.value.id
      }
    } catch (err) {
      console.error(err)
    }
  }

  const loadGuestCartId = () => {
    const cartCookie = useCookie(guestCartIdCookieName.value)
    return cartCookie.value
  }

  const deleteGuestCartId = () => {
    const cartCookie = useCookie(guestCartIdCookieName.value)
    cartCookie.value = null
  }

  const loadCustomerCart = async () => {
    try {
      if (loggedIn.value) await initUserMeta()
      if (!userMeta.value?.commercelayer_id) return null
      const [order] = await $cl.customers
        .orders(userMeta.value.commercelayer_id, {
          fields: ['id'],
          filters: {
            status_in: 'draft,pending',
            market_code_eq: marketCode.value ?? '',
          },
          pageSize: 1,
        })
        .catch(() => [])

      return order
        ? await $cl.orders
            .retrieve(order.id, {
              include: ['line_items'],
            })
            .catch(() => null)
        : null
    } catch {
      return null
    }
  }

  const loadCartId = async () => {
    const guestCartId = loadGuestCartId()
    if (!loggedIn.value) return guestCartId

    const customerCart = await loadCustomerCart()
    if (!customerCart && !guestCartId) return null
    if (!guestCartId && customerCart) return customerCart.id
    if (guestCartId && !customerCart) {
      await $cl.orders.update({
        id: guestCartId,
        customer_email: user.value?.email,
      })
      deleteGuestCartId()
      return guestCartId
    }

    const currentPath = route?.path.endsWith('/')
      ? route?.path.slice(0, -1)
      : route?.path

    const guestOrder = await $cl.orders
      .retrieve(guestCartId ?? '', {
        include: ['line_items'],
      })
      .catch(() => null)

    if (!guestOrder) return customerCart?.id ?? ''

    if ([cartUrl, checkoutUrl].includes(currentPath)) {
      // replace all line_items in customer order with guest order line_items
      const lineItemsToAdd =
        guestOrder.line_items?.map(lineItem => ({
          sku_code: lineItem.sku_code,
          quantity: lineItem.quantity,
          metadata: lineItem.metadata,
        })) ?? []

      const lineItemsToDelete =
        customerCart?.line_items?.map(lineItem => lineItem.id) ?? []

      await deleteLineItems(lineItemsToDelete)
      await createLineItems(customerCart?.id ?? '', lineItemsToAdd)
      deleteGuestCartId()
      return customerCart?.id ?? ''
    }

    // merge guest order line_items to customer order
    const lineItemsToAdd =
      guestOrder.line_items?.flatMap(guestLineItem => {
        if (guestLineItem.item_type !== 'skus') return []
        const customerCartLineItem = customerCart?.line_items?.find(
          customerLineItem =>
            customerLineItem.sku_code === guestLineItem.sku_code
        )
        if (customerCartLineItem) return []
        return {
          sku_code: guestLineItem.sku_code,
          quantity: guestLineItem.quantity,
          metadata: guestLineItem.metadata,
        }
      }) ?? []

    await createLineItems(customerCart?.id ?? '', lineItemsToAdd)
    deleteGuestCartId()
    return customerCart?.id

    async function createLineItems(
      orderId: string,
      lineItems: Partial<LineItem>[]
    ) {
      for (const lineItem of lineItems) {
        if (lineItem && lineItem.sku_code) {
          await $cl.line_items.create({
            ...lineItem,
            // @ts-ignore
            item_type: 'sku',
            _update_quantity: true,
            order: { id: orderId, type: 'orders' },
          })
        }
      }
    }

    async function deleteLineItems(lineItems: string[]) {
      for (const lineItemId of lineItems) {
        await $cl.line_items.delete(lineItemId)
      }
    }
  }

  const alignPromotionsForCart = async () => {
    if (route.path.includes('adyen') || route.path.includes('paypal')) return
    if (!cart.lineItems.value.length) {
      return
    }

    try {
      await cart.updateOrder({
        _refresh: true,
      })
    } catch {
      console.error(
        `ERROR CART ${cart.orderId} NOT UPDATE ON FUNCTION alignPromotionsForCart`
      )
    }
  }

  // INIT
  const initCart = async () => {
    const id = (await loadCartId()) ?? null
    cart.initOrder(id)
    await cart.fetchOrder()
    if (isPlaceholderAddress(cart.order?.value?.shipping_address)) {
      await removeAddresses()
    }
    await alignPromotionsForCart()
    await setCartLangAndSalesforceId()
    await fetchAlgoliaProducts()
    cartInitialized.value = true
    saveGuestCartId()
    resetAbandonedCartCheck()
    checkAbandonedCart()
  }

  // RESET
  const resetCart = () => {
    cart.initOrder(null)
    if (!loggedIn.value) deleteGuestCartId()
    resetAbandonedCartCheck({ skipCheck: true })
  }

  // CART METHODS

  const createCartIfNotExisting = async () => {
    if (!cart.order.value) {
      await cart.createOrder()
      await setCartLangAndSalesforceId()
    }
    saveGuestCartId()
  }

  const refreshCart = async () => {
    await cart.fetchOrder()
    await fetchAlgoliaProducts()
  }

  const lineItemsWithAlgoliaData = computed(() =>
    cart.lineItems.value.map(el => {
      const algoliaProduct =
        algoliaProducts.value?.[getSkuWithoutSize(el.sku_code)!]

      const path = algoliaProduct ? pdpPath(algoliaProduct) : ''

      return {
        ...el,
        path,
        _algolia: algoliaProduct as typeof algoliaProduct | null,
      }
    })
  )

  const addToCart = async (
    attributes: MyLineItemCreate,
    quantity = 1,
    refresh = true
  ) => {
    if (!cart.order.value) {
      await createCartIfNotExisting()
    } else {
      await refreshCart()
    }
    const itemQuantityInCart =
      cart.lineItems.value.find(i => i.sku_code === attributes.sku_code)
        ?.quantity ?? 0
    const isWithinLimits = checkNewQuantityAgainstLimits(
      attributes.sku_code!,
      itemQuantityInCart + quantity
    )
    if (!isWithinLimits) return
    const item = await cart.addLineItem(attributes, quantity, refresh)
    await fetchAlgoliaProducts()

    const _cart = cart.order.value
    if (_cart && item)
      setCartEventsPayload({
        cart: { ..._cart, line_items: [item] },
      })
    lastAdded.value = item!

    return item
  }

  const checkAbandonedCart = () => {
    if (loggedIn.value) {
      if (abandonedCartChecked.value) return
      abandonedCartChecked.value = true
    }

    if (!loggedIn.value) {
      if (abandonedGuestCartChecked.value) return
      abandonedGuestCartChecked.value = true
    }

    if (!cart.lineItems.value.length) return
    openDialog()
  }

  const resetAbandonedCartCheck = ({ skipCheck = false } = {}) => {
    if (loggedIn.value && !skipCheck) return
    abandonedCartChecked.value = false
  }

  const checkEditableCart = cart.checkEditable

  const checkNewQuantityAgainstLimits = (id: string, newQuantity: number) => {
    const totalOfOtherSkus =
      cart.lineItems.value
        ?.filter(i => i.id !== id)
        ?.reduce((acc, item) => (acc += item.quantity), 0) ?? 0

    if (newQuantity > currentMarketSettings.value.max_MFCS) {
      addSnackbar(`${id}-overSkuLimit`, {
        message: {
          key: 'cart.cantExceedLineItemQuantityLimit',
          named: { n: currentMarketSettings.value.max_MFCS },
        },
        duration: 0,
        textClasses: 'text-primitives-red',
      })
      return false
    }
    if (totalOfOtherSkus + newQuantity > currentMarketSettings.value.max_cart) {
      addSnackbar(`${id}-overCartLimit`, {
        message: {
          key: 'cart.cantExceedTotalCartLimit',
          named: { n: currentMarketSettings.value.max_cart },
        },
        duration: 0,
        textClasses: 'text-primitives-red',
      })
      return false
    }
    return true
  }

  const removeFromCart = async (id: string, refresh = true) => {
    const item = await cart.removeLineItem(id, refresh)
    const _cart = cart.order.value
    if (_cart && item)
      setCartEventsPayload({
        cart: { ..._cart, line_items: [item] },
      })
    return item
  }

  const removeFromCartBySku = cart.removeLineItemBySku

  const updateQuantity = async (
    id: string,
    quantity: number,
    refresh?: boolean
  ) => {
    await refreshCart()
    const isWithinLimits = checkNewQuantityAgainstLimits(id, quantity)
    if (!isWithinLimits) return
    cart.updateQuantity(id, quantity, refresh)
  }

  const updateQuantityBySku = async (skuCode: string, quantity: number) => {
    await refreshCart()
    const itemInCart = cart.lineItems.value.find(i => i.sku_code === skuCode)
    if (itemInCart) {
      const isWithinLimits = checkNewQuantityAgainstLimits(
        itemInCart.id,
        quantity
      )
      if (!isWithinLimits) return
    }
    cart.updateQuantityBySku(skuCode, quantity)
  }

  const increaseQuantity = async (id: string, amount = 1) => {
    await refreshCart()
    const itemQuantityInCart =
      cart.lineItems.value.find(i => i.id === id)?.quantity ?? 0

    const isWithinLimits = checkNewQuantityAgainstLimits(
      id,
      itemQuantityInCart + amount
    )
    if (!isWithinLimits) return

    lineItemsQtyUpdating[id] = true
    const item = await cart.increaseQuantity(id, amount).finally(() => {
      lineItemsQtyUpdating[id] = false
    })

    const _cart = cart.order.value
    if (_cart && item) {
      const discount = item?.discount_float ?? 0
      setCartEventsPayload({
        cart: {
          ..._cart,
          line_items: [
            {
              ...item,
              discount_float: discount + discount / (item.quantity - 1),
            },
          ],
        },
      })
    }
    return item
  }

  const decreaseQuantity = async (id: string, amount = 1) => {
    lineItemsQtyUpdating[id] = true
    const item = await cart
      .decreaseQuantity(id, amount)
      .finally(() => (lineItemsQtyUpdating[id] = false))
    const _cart = cart.order.value
    if (_cart && item) {
      const discount = item?.discount_float ?? 0
      setCartEventsPayload({
        cart: {
          ..._cart,
          line_items: [
            {
              ...item,
              discount_float: discount - discount / (item.quantity + 1),
            },
          ],
        },
      })
    }
    return item
  }

  const isInCart = cart.isInOrder

  const initCompare = cart.initCompare

  const removeFromSoldOutCompareData = cart.removeFromSoldOutCompareData
  const removeFromLimitedCompareData = cart.removeFromLimitedCompareData

  const applyCoupon = async (couponCode: string) => {
    try {
      await createCartIfNotExisting()
      await cart.updateOrder(
        {
          coupon_code: couponCode,
        },
        false
      )
    } catch (error) {
      throw createError({
        statusCode: 500,
        message: `Coupon code not valid yet.`,
      })
    }
    try {
      await checkShippingMethod(true)
    } catch {
      await cart.fetchOrder()
    }
  }

  const totalAmoutOrder = computed(() => {
    return cart.order.value?.total_amount_float
    // const order = cart.order.value || {}
    // const shippingMethodPrice =
    //   shippingInfos.shippingInfos.value?.shippingMethod?.price_amount_float || 0
    // const isFreeShipping =
    //   shippingInfos.shippingInfos.value?.freeShipping || false

    // const subtotalAmount = order.subtotal_amount_float || 0
    // const discountAmount = order.discount_amount_float || 0
    // const giftCardAmount = order.gift_card_amount_float || 0

    // const totalAmount =
    //   subtotalAmount +
    //   discountAmount +
    //   giftCardAmount +
    //   (isFreeShipping ? 0 : shippingMethodPrice)

    // return totalAmount
  })

  const isPickupInStoreDisabled = computed(() => {
    // the lineItem IDs that contain the "PickUp_In_Store": "Disabled" metadata
    const pickupInStoreDisablingPromoLineItemIDs =
      cart.order.value?.line_items
        ?.filter(i => {
          const pickupDisabledMetadata = i.item?.metadata?.PickUp_In_Store
          if (pickupDisabledMetadata)
            return (
              pickupDisabledMetadata.toString().toLowerCase() === 'disabled'
            )
        })
        ?.map(i => i.id) ?? []

    const allLineItemsContainPickupDisablingPromotion =
      cart.lineItems.value.every(
        // whether ALL the skus in the cart include the pickup-disabling promotion
        sku =>
          !!sku.discount_breakdown &&
          Object.keys(sku.discount_breakdown).find(discountCode =>
            pickupInStoreDisablingPromoLineItemIDs.includes(discountCode)
          )
      )
    return allLineItemsContainPickupDisablingPromotion
  })

  const applyGiftCard = async (giftCardCode: string) => {
    await cart.updateOrder({
      gift_card_code: giftCardCode,
    })
  }

  const setCartLangAndSalesforceId = async () => {
    if (loggedIn.value) await initUserMeta()

    const orderUpdate: Partial<OrderUpdate> = {}

    if (
      appConfig.currentLanguage &&
      cart.order?.value?.language_code !== appConfig.currentLanguage
    ) {
      orderUpdate.language_code = appConfig.currentLanguage
    }

    if (
      userMeta.value?.salesforce_id &&
      cart.order.value?.metadata?.salesforceId !== userMeta.value?.salesforce_id
    ) {
      orderUpdate.metadata = {
        ...cart.order.value?.metadata,
        salesforceId: userMeta.value?.salesforce_id,
      }
    }

    if (!Object.keys(orderUpdate).length) return

    await cart.updateOrder(orderUpdate)
  }

  const setCustomerEmail = async (customerEmail: string) => {
    await cart.updateOrder({
      customer_email: customerEmail,
    })
  }

  const setMetadata = async (
    metadata: OrderMetadata | JapanDeliveryMetadata
  ) => {
    await cart.updateOrder({
      metadata: { ...cart.order.value?.metadata, ...metadata },
    })
  }

  const setShippingAddress = async (shippingAddress: AddressCreate) => {
    const address = await $cl.addresses.create(shippingAddress)
    await cart.updateOrder({
      shipping_address: address,
    })
  }

  const removeAddresses = async () => {
    await cart.updateOrder({
      shipping_address: {
        type: 'addresses',
        id: null,
      },
      billing_address: {
        type: 'addresses',
        id: null,
      },
    })
  }

  const setShippingMethod = async (id: string) => {
    const shipments = cart.allOrderShipments.value

    for (const shipment of shipments) {
      await $cl.shipments.update({
        id: shipment.id,
        shipping_method: { id, type: 'shipping_methods' },
      })
    }
    await cart.updateOrderMetadata({
      shippingMethodId: id,
    })
  }

  const setBillingAddress = async (billingAddress: AddressCreate) => {
    const address = await $cl.addresses.create(billingAddress)
    await cart.updateOrder({
      billing_address: address,
    })
  }

  const setBillingAddressSameAsShippingAddress = async () => {
    await cart.updateOrder({ _billing_address_same_as_shipping: true })
  }

  const updateBillingAddress = async (address: Omit<AddressUpdate, 'id'>) => {
    if (!cart.order.value?.billing_address) return

    return $cl.addresses.update({
      id: cart.order.value?.billing_address.id,
      ...address,
    })
  }

  const setPaymentMethod = async (id: string) => {
    await cart.updateOrder({
      payment_method: {
        id,
        type: 'payment_methods',
      },
    })
  }

  const setAdyenPaymentSource = async () => {
    const paymentSource = await $cl.adyen_payments.create({
      order: {
        id: cart.orderId.value!,
        type: 'orders',
      },
    })
    await refreshCart()
    return paymentSource
  }

  const updateAdyenPaymentSource = async (
    adyenPaymentUpdate: Omit<AdyenPaymentUpdate, 'id'>
  ) => {
    // @ts-expect-error
    if (adyenPaymentUpdate._authorize || adyenPaymentUpdate._details) {
      try {
        await checkShippingMethod()
        // eslint-disable-next-line no-empty
      } catch {}
    }
    const paymentSource = await $cl.adyen_payments.update({
      id: cart.order.value!.payment_source!.id!,
      ...adyenPaymentUpdate,
    })
    await refreshCart()
    return paymentSource
  }

  const setPaypalPaymentSource = async (
    paypalPaymentCreate: Omit<PaypalPaymentCreate, 'order'>
  ) => {
    const paymentSource = await $cl.paypal_payments.create({
      ...paypalPaymentCreate,
      order: {
        id: cart.orderId.value!,
        type: 'orders',
      },
    })
    await refreshCart()
    return paymentSource
  }

  const updatePaypalPaymentSource = async (
    paypalPaymentUpdate: Omit<PaypalPaymentUpdate, 'id'>
  ) => {
    const paymentSource = await $cl.paypal_payments.update({
      id: cart.order.value!.payment_source!.id!,
      ...paypalPaymentUpdate,
    })
    await refreshCart()
    return paymentSource
  }

  const setWireTransferPaymentSource = async () => {
    const paymentSource = await $cl.wire_transfers.create({
      order: {
        id: cart.orderId.value!,
        type: 'orders',
      },
    })
    await refreshCart()
    return paymentSource
  }

  const updateLineItemsMetadataOnOrderPlace = async () => {
    await fetchAlgoliaProducts()

    const updatePromises = lineItemsWithAlgoliaData.value.map(
      async lineItem => {
        if (lineItem.id) {
          await $cl.line_items.update({
            id: lineItem.id,
            metadata: {
              ...lineItem.metadata,
              mainCategory: lineItem._algolia?.mainCategory?.slug ?? '',
              productName: lineItem._algolia?.Name ?? '',
              path: lineItem._algolia && pdpPath(lineItem._algolia),
            },
          })
        }
      }
    )

    await Promise.all(updatePromises)
  }

  const updateOrderMetadataOnOrderPlace = async () => {
    let clientIp: string = 'local_dev_environment'
    if (!import.meta.dev) {
      const res = await $fetch<{ ip: string }>('/api/ip')
      clientIp = res.ip
    }
    const cookiesUserSession = useCookie<{ name: string } | undefined>(
      'riskified-cookie'
    )

    await cart.updateOrderMetadata({
      clientIp: clientIp,
      userAgent: window.navigator.userAgent,
      acceptedLanguages: navigator.languages,
      sessionId: cookiesUserSession.value?.name ?? '',
    })
  }

  const place = async () => {
    await Promise.all([
      updateOrderMetadataOnOrderPlace(),
      updateLineItemsMetadataOnOrderPlace(),
    ])
    try {
      await checkShippingMethod()
      // eslint-disable-next-line no-empty
    } catch {}
    return cart.updateOrder({
      _place: true,
    })
  }

  const checkShippingMethod = async (refresh = false) => {
    const order = cart.order.value
    if (!order) {
      if (refresh) await cart.fetchOrder()
      return
    }
    const orderWithShipments = await $cl.orders.retrieve(order.id, {
      include: ['shipments', 'shipments.shipping_method'],
    })

    const shipments = orderWithShipments.shipments
    if (!shipments) {
      if (refresh) await cart.fetchOrder()
      return
    }

    const shipmentsWithoutShippingMethod = shipments.filter(
      shipment => !shipment.shipping_method
    )
    if (!shipmentsWithoutShippingMethod.length) {
      if (refresh) await cart.fetchOrder()
      return
    }

    const shippingMethodId = order?.metadata?.shippingMethodId
    if (!shippingMethodId) {
      if (refresh) await cart.fetchOrder()
      return
    }

    for (const shipment of shipmentsWithoutShippingMethod) {
      await $cl.shipments.update({
        id: shipment.id,
        shipping_method: {
          id: shippingMethodId,
          type: 'shipping_methods',
        },
      })
    }
    if (refresh) await cart.fetchOrder()
  }

  const skusCount = computed(() => cart.order.value?.skus_count ?? 0)

  // DON'T TOUCH! THIS IS USED FOR FEDERATED APP
  watch(
    skusCount,
    () => {
      if (!isFederatedApp.value) {
        federatedCookieCartLineItemsCount.value =
          cart.order.value?.skus_count ?? 0
      }
    },
    {
      deep: true,
      immediate: true,
    }
  )

  // watch(userId, () => initCart())

  return {
    // CART
    cartId: cart.orderId,
    cart: cart.order,
    cartInitialized,
    soldOutCompareData: cart.soldOutCompareData,
    limitedCompareData: cart.limitedCompareData,
    priceChangedCompareData: cart.priceChangedCompareData,
    allOrderShipments: cart.allOrderShipments,
    lineItems: cart.lineItems,
    lineItemsWithAlgoliaData,
    freeGiftPromotions: cart.freeGiftPromotions,
    lineItemsQtyUpdating,
    promotionGifts: cart.promotionGifts,
    giftCards: cart.giftCards,
    shipments: cart.shipments,
    adjustments: cart.adjustments,
    totalAmoutOrder,
    lastAdded,
    subtotalAmountFloatWithoutFreeGifts:
      cart.subtotalAmountFloatWithoutFreeGifts,
    discountAmountFloatWithoutFreeGifts:
      cart.discountAmountFloatWithoutFreeGifts,
    freeGiftPromotionsAmountFloat: cart.freeGiftPromotionsAmountFloat,
    couponLineItems: cart.couponLineItems,
    promoLineItems: cart.promoLineItems,
    isPickupInStoreDisabled,

    // CART METHODS
    initCart,
    refreshCart,
    resetCart,
    addToCart,
    removeFromSoldOutCompareData,
    removeFromLimitedCompareData,
    checkEditableCart,
    // getLineItemAttributes,
    removeFromCart,
    removeFromCartBySku,
    updateQuantity,
    updateQuantityBySku,
    increaseQuantity,
    decreaseQuantity,
    isInCart,
    applyCoupon,
    applyGiftCard,
    setCustomerEmail,
    setMetadata,
    setShippingAddress,
    removeAddresses,
    setShippingMethod,
    setBillingAddress,
    setBillingAddressSameAsShippingAddress,
    updateBillingAddress,
    setPaymentMethod,
    setAdyenPaymentSource,
    updateAdyenPaymentSource,
    setPaypalPaymentSource,
    updatePaypalPaymentSource,
    setWireTransferPaymentSource,
    place,

    initCompare,
    alignPromotionsForCart,

    // FEDERATED APP
    federatedCookieCartLineItemsCount,
  }
}

<script lang="ts">
import snarkdown from 'snarkdown'
// NB: Tables are not yet supported in snarkdown.

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    container: {
      type: String,
      default: 'div',
    },
    isPreprocessed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const localePath = useLocalePath()

    const preprocessContent = (content: string) => {
      if (!content) return ''
      // Convert [lang=en](word) to <span lang="xx">word</span>
      let processedContent = content?.replace(
        /(?:\[lang=([a-z]{2})\]\((.*?)\)|\\\[lang=([a-z]{2})\\\]\((.*?)\))/gi,
        (match, p1, p2, p3, p4) => {
          const lang = p1 || p3
          const word = p2 || p4
          return `<span lang="${lang}">${word}</span>`
        }
      )

      // Handle links that start with "/"
      processedContent = processedContent?.replace(
        /\[([^\]]+)\]\((\/[^\)]+)\)/g,
        (match, text, link) => {
          return `<a href="${localePath(link)}">${text}</a>`
        }
      )

      // Convert lines starting with 2 spaces and a dash to nested lists
      processedContent = processedContent.replace(
        /(?:^|\n) {2}-\s+(.+?)(?=\n|$)/g,
        (match, p1) => {
          return `<ul><li>${p1.trim()}</li></ul>`
        }
      )

      // Convert lines starting with 2 spaces and a number followed by a dot to ordered lists
      processedContent = processedContent.replace(
        /(?:^|\n) {2}(\d+)\.\s+(.+?)(?=\n|$)/g,
        (match, p1, p2) => {
          return `<br/><div style="padding-left: 1.25rem" >${p1}. ${p2.trim()}</div>`
        }
      )

      // Remove three consecutive spaces
      processedContent = processedContent?.replace(/ {3,}/g, '')

      // After handling lists, convert any <br/> and <u>
      if (!props.isPreprocessed) return processedContent
      processedContent = processedContent.replace(/\\n/g, '<br/>')
      processedContent = processedContent.replace(/__(.*?)__/g, '<u>$1</u>')

      return processedContent
    }

    const md = ref(props?.content)
    const preprocessedContent = preprocessContent(md?.value)
    let html = snarkdown(preprocessedContent)
    // This is to add *, because snarkdown removes it
    html = html.replace(/\\\*(?=[^*]|$)/g, '*')

    return () =>
      h(props?.container ?? 'div', {
        innerHTML: html,
      })
  },
})
</script>

import type {
  LineItem,
  Order,
  FreeGiftPromotion,
  Shipment,
  Sku,
  OrderUpdate,
  QueryParamsRetrieve,
  LineItemCreate,
  GiftCard,
  Adjustment,
  OrderCreate,
} from '@commercelayer/sdk'

export const defaultQuery: QueryParamsRetrieve = {
  include: [
    'line_items',
    'line_items.item',
    'available_free_skus',
    'shipments',
    'shipments.available_shipping_methods',
    'shipments.available_shipping_methods.delivery_lead_time_for_shipment',
    'shipments.delivery_lead_time',
    'shipments.shipping_method',
    'shipping_address',
    'billing_address',
    'available_payment_methods',
    'payment_method',
    'payment_source',
  ],
}

export const skusQuery: QueryParamsRetrieve = {
  include: ['prices'],
}

type LineItem_<Item extends LineItem['item']> = LineItem & {
  item: Item
}

export const getFreeGiftQuantity =
  (freeGiftLineItems: LineItem[]) =>
  (skuLineItem: LineItem_<Sku>): number => {
    // if the amount is zero, all items are free
    if (!skuLineItem.unit_amount_cents) {
      return skuLineItem.quantity!
    }

    // find the number of free gifts by comparing the total free gift discount amount with the total amount
    // sum all free gift discounts amount
    const freeGiftDiscountAmountCents = Object.entries(
      skuLineItem?.discount_breakdown ?? {}
    )
      .filter(([discountLineItemId]) =>
        freeGiftLineItems.map(({ id }) => id).includes(discountLineItemId)
      )
      .map(([, { cents }]) => cents)
      .reduce((a, b) => a + b, 0)

    // if the total free gift discount is zero no items are free
    if (!freeGiftDiscountAmountCents) {
      return 0
    }

    // get the number of free gift items using the ratio between the total free gift discount amount and the total amount
    return Math.round(
      skuLineItem.quantity! *
        (Math.abs(freeGiftDiscountAmountCents) /
          skuLineItem.total_amount_cents!)
    )
  }

/**
 * Order api.
 * @param {Sting} id order id.
 * @param {Object} { isCheckEditableEnabled, query }
 * @returns {Object} order data and methods.
 */
export default (
  key: string,
  id = null,
  { isCheckEditableEnabled = true, query = defaultQuery } = {}
) => {
  const { $cl } = useNuxtApp()
  const { addSnackbar } = useHeaderSnackbars()

  const orderId = useState<string | null>(`orderId-${key}`, () => id)

  const initOrder = (id: string | null) => {
    orderId.value = id
  }

  const soldOutCompareData = useState<LineItem_<Sku>[]>(
    `sold-out-compare-data-${key}`,
    () => []
  )

  const limitedCompareData = useState<LineItem_<Sku>[]>(
    `limited-compare-data-${key}`,
    () => []
  )

  const priceChangedCompareData = useState<LineItem_<Sku>[]>(
    `price-changed-compare-data-${key}`,
    () => []
  )

  watch(orderId, orderId => {
    if (!orderId) {
      order.value = null
    }
  })

  // ORDER DATA
  const order = useState<Order | null>(`order-${key}`, () => null)

  // SINTACTIC SUGAR TO ACCESS ORDER DATA
  const allLineItems = computed<LineItem[]>(() => order.value?.line_items ?? [])
  const allOrderShipments = computed<Shipment[]>(
    () => order.value?.shipments ?? []
  )

  const lineItems = computed(
    () =>
      allLineItems.value.filter(
        lineItem => lineItem.item_type === 'skus'
      ) as LineItem_<Sku>[]
  )
  const freeGiftPromotions = computed(
    () =>
      allLineItems.value.filter(
        lineItem => lineItem.item_type === 'free_gift_promotions'
        // @ts-ignore
      ) as LineItem_<FreeGiftPromotion>[]
  )
  const giftCards = computed(
    () =>
      allLineItems.value.filter(
        lineItem => lineItem.item_type === 'gift_cards'
      ) as LineItem_<GiftCard>[]
  )
  const shipments = computed(
    () =>
      allLineItems.value.filter(
        lineItem => lineItem.item_type === 'shipments'
      ) as LineItem_<Shipment>[]
  )
  const adjustments = computed(
    () =>
      allLineItems.value.filter(
        lineItem => lineItem.item_type === 'adjustments'
      ) as LineItem_<Adjustment>[]
  )
  const freeGiftPromotionsAmountFloat = computed(() =>
    freeGiftPromotions.value
      .map(lineItem => lineItem.total_amount_float)
      .reduce((a, b) => a + b, 0)
  )
  const subtotalAmountFloatWithoutFreeGifts = computed(
    () =>
      order.value &&
      order.value.subtotal_amount_float! + freeGiftPromotionsAmountFloat.value
  )
  const discountAmountFloatWithoutFreeGifts = computed(
    () =>
      order.value &&
      order.value.discount_amount_float! - freeGiftPromotionsAmountFloat.value
  )
  const couponLineItems = computed(() =>
    allLineItems.value.filter(lineItem => lineItem.coupon_code)
  )
  const promoLineItems = computed(() =>
    allLineItems.value.filter(
      lineItem =>
        !lineItem.coupon_code &&
        lineItem.item_type &&
        ['free_shipping_promotions', 'fixed_amount_promotions'].includes(
          lineItem.item_type
        )
    )
  )

  // LOADING UTILS
  const loading = ref(true)
  const appConfig = useAppConfig()

  // CREATE, UPDATE, FETCH, INIT METHODS
  const createOrder = async (attributes: OrderCreate = {}) => {
    if (order.value) {
      return
    }

    loading.value = true

    order.value = await $cl.orders.create(
      {
        ...attributes,
        language_code: appConfig.currentLanguage,
        // shipping_country_code_lock: 'IT',
        // language_code: "IT",
        // return_url: process.env.BASE_URL,
      },
      query
    )

    orderId.value = order.value.id

    loading.value = false
  }

  const updateOrder = async (
    params: Omit<OrderUpdate, 'id'>,
    refresh = true
  ) => {
    if (!order.value) {
      return
    }

    loading.value = true

    const updatedOrder = await $cl.orders.update(
      {
        id: order.value.id,
        ...params,
      },
      query
    )
    if (refresh) await fetchOrder()

    loading.value = false

    return updatedOrder
  }

  const updateOrderMetadata = async (metadata: Order['metadata']) =>
    updateOrder({
      metadata: { ...order.value?.metadata, ...metadata },
    })

  const addPrices = async (order: Order) => {
    const skuCodes =
      order.line_items
        ?.filter(l => l?.item?.type === 'skus')
        ?.map(l => l.sku_code!) ?? []

    const allSkus: Sku[] = []

    for (const skuCodesChunk of chunk(skuCodes, 10)) {
      const skus =
        skuCodesChunk.length > 0
          ? await $cl.skus.list({
              ...skusQuery,
              filters: { code_in: skuCodesChunk.join(',') },
            })
          : []

      allSkus.push(...skus)
    }

    return {
      ...order,
      line_items: order.line_items?.map(l => ({
        ...l,
        item:
          l.item_type !== 'skus'
            ? l.item
            : ({
                ...l.item,
                prices: allSkus?.find(s => s.code === l.sku_code)?.prices,
              } as Sku),
      })),
    }
  }

  const fetchOrder = async () => {
    if (!orderId.value) {
      return
    }

    loading.value = true

    try {
      order.value = await $cl.orders.retrieve(orderId.value, query)
      checkEditable()
      await checkFreeGifts()
    } catch (err: any) {
      console.error('Error', err.response || err)
    }

    loading.value = false
  }

  const initCompare = async () => {
    if (!order.value) {
      return
    }
    order.value = await addPrices(order.value)

    const soldOut = lineItems.value?.filter(
      item => !(item as any).item?.inventory?.available
    )
    const limitedInventory = lineItems.value?.filter(
      item =>
        (item as any).item?.inventory?.available &&
        item.quantity > (item as any).item.inventory.quantity
    )
    const priceChanged = lineItems.value?.filter(
      item => item?.item?.prices?.[0]?.amount_float !== item?.unit_amount_float
    )

    // DO NOT REMOVE AUTOMATICALLY PRODUCTS BUT LEAVE THE USER TO MAKE IT
    // for (const lineItem of soldOut) {
    //   await removeLineItemBySku(lineItem.sku_code!, false)
    // }

    for (const lineItem of limitedInventory) {
      await updateQuantity(
        lineItem.id,
        lineItem.item.inventory!.quantity,
        false
      )
    }

    for (const lineItem of priceChanged) {
      await updatePrice(lineItem.sku_code!, false)
    }

    if (limitedInventory?.length || priceChanged?.length) {
      await fetchOrder()
    }

    soldOutCompareData.value = soldOut
    limitedCompareData.value = limitedInventory
    priceChangedCompareData.value = priceChanged

    if (soldOutCompareData.value && soldOutCompareData.value.length > 0) {
      soldOutCompareData.value.forEach(lineItem => {
        addSnackbar(`soldOutCompareData`, {
          message: {
            key: 'checkout.soldOutCompareData',
            named: { product: lineItem?.name ?? '' },
          },
          duration: 0,
          textClasses: 'text-primitives-red',
        })
      })
    }
    if (limitedCompareData.value && limitedCompareData.value.length > 0) {
      limitedCompareData.value.forEach(lineItem => {
        addSnackbar(`limitedCompareData`, {
          message: {
            key: 'checkout.limitedCompareData',
            named: { product: lineItem?.name ?? '' },
          },
          duration: 0,
          textClasses: 'text-primitives-red',
        })
      })
    }
    if (
      priceChangedCompareData.value &&
      priceChangedCompareData.value.length > 0
    ) {
      priceChangedCompareData.value.forEach(lineItem => {
        addSnackbar(`priceChangedCompareData`, {
          message: {
            key: 'checkout.priceChangedCompareData',
            named: { product: lineItem?.name ?? '' },
          },
          duration: 0,
          textClasses: 'text-primitives-red',
        })
      })
    }

    const hasRemovedLineItems = !!soldOut?.length || !!limitedInventory?.length
    return hasRemovedLineItems
  }

  const removeFromSoldOutCompareData = (id: string) => {
    soldOutCompareData.value = soldOutCompareData.value.filter(
      item => item.id !== id
    )
  }

  const removeFromLimitedCompareData = (id: string) => {
    limitedCompareData.value = limitedCompareData.value.filter(
      item => item.id !== id
    )
  }

  // CHECK METHODS
  // Discard not editable orders
  const checkEditable = () => {
    if (isCheckEditableEnabled && order.value && !order.value.editable) {
      orderId.value = null
      order.value = null
    }
  }

  watch(order, checkEditable)

  const addLineItem = async (
    attributes: Omit<LineItemCreate, 'quantity' | 'order'>,
    quantity = 1,
    refresh = true
  ) => {
    if (!order.value) {
      return
    }

    if (quantity <= 0) return

    const lineItem = await $cl.line_items.create({
      ...attributes,
      quantity,
      // @ts-ignore
      item_type: 'sku',
      _update_quantity: true,
      order: { id: order.value!.id, type: 'orders' },
    })

    if (refresh) await fetchOrder()

    return lineItems.value.find(item => item.id === lineItem.id)
  }

  const removeLineItem = async (id: string, refresh = true) => {
    const item = lineItems.value.find(item => item.id === id)
    if (!item) return
    await $cl.line_items.delete(id)
    if (refresh) await fetchOrder()
    return item
  }

  const removeLineItemBySku = async (skuCode: string, refresh = true) => {
    const id = lineItems.value.find(item => item.sku_code === skuCode)?.id
    if (id === undefined) return
    return removeLineItem(id, refresh)
  }

  const updatePrice = async (id: string, refresh = true) => {
    let item = lineItems.value.find(item => item.id === id)
    if (!item) return
    // @ts-ignore
    item = await $cl.line_items.update({
      id,
      metadata: {
        ...item.metadata,
        priceChanged: (item.metadata?.priceChanged ?? 0) + 1,
      },
    })
    if (refresh) await fetchOrder()

    return item
  }

  const updateQuantity = async (
    id: string,
    quantity: number,
    refresh = true
  ) => {
    if (quantity === 0) {
      return removeLineItem(id, refresh)
    }

    let item = lineItems.value.find(item => item.id === id)
    if (!item) return
    // @ts-ignore
    item = await $cl.line_items.update({
      id,
      quantity,
    })
    if (refresh) {
      await fetchOrder()
      item = lineItems.value.find(item => item.id === id)
    }

    return item
  }

  const isFreeSkuNotAvailableAndOutOfCatalogue =
    (availableFreeSkus?: Sku[]) => (lineItem: LineItem_<Sku>) => {
      const isFreeSkuAvailable = availableFreeSkus
        ?.map(sku => sku.code)
        ?.includes(lineItem.sku_code!)
      const isOutOfCatalogue = lineItem?.metadata?.productInCatalogue === false
      return !isFreeSkuAvailable && isOutOfCatalogue
    }

  let checkingFreeGift = false
  const checkFreeGifts = async () => {
    if (checkingFreeGift) return
    checkingFreeGift = true
    const _lineItems = lineItems.value.slice()
    const _availableFreeSkus = order.value?.available_free_skus?.slice()
    const allLineItemsOutOfCatalogue = _lineItems.every(
      data => data?.metadata?.productInCatalogue === false
    )

    const freeGiftsToRemove = allLineItemsOutOfCatalogue
      ? _lineItems
      : _lineItems.filter(
          isFreeSkuNotAvailableAndOutOfCatalogue(_availableFreeSkus)
        )

    for (const freeGift of freeGiftsToRemove) {
      await removeLineItem(freeGift.id, false)
    }

    if (freeGiftsToRemove.length) await fetchOrder()

    if (_lineItems.length && _availableFreeSkus?.length) {
      const notInCartAvailableFreeSkus = _availableFreeSkus.filter(
        sku =>
          !_lineItems.map(item => item.sku_code).includes(sku.code) &&
          sku?.inventory?.available
      )

      for (const sku of notInCartAvailableFreeSkus) {
        let product = null

        try {
          if (sku.code) {
            product = await $fetch(`/api/account/getProductInfo/${sku.code}`)
          }
        } catch (error) {
          console.log(error)
        }

        await addLineItem(
          {
            sku_code: sku.code,
            metadata: {
              productInCatalogue: !!product,
              // @ts-ignore
              product_image: product?.product_image,
              // @ts-ignore
              slug: product?.slug ? `p-${product.slug}` : undefined,
            },
          },
          1,
          false
        )
      }

      if (notInCartAvailableFreeSkus.length) await fetchOrder()
    }
    checkingFreeGift = false
  }

  const updateQuantityBySku = async (skuCode: string, quantity: number) => {
    const id = lineItems.value.find(item => item.sku_code === skuCode)?.id
    if (id === undefined) return
    return updateQuantity(id, quantity)
  }

  const increaseQuantity = async (id: string, amount = 1) => {
    const item = lineItems.value.find(item => item.id === id)
    if (!item) return
    return updateQuantity(id, Math.max(0, item.quantity! + amount))
  }

  const decreaseQuantity = async (id: string, amount = 1) => {
    const item = lineItems.value.find(item => item.id === id)
    if (!item) return
    return updateQuantity(id, Math.max(0, item.quantity! - amount))
  }

  const isInOrder = (sku: string) => {
    return lineItems.value.some(item => item.sku_code === sku)
  }

  const promotionGifts = computed(() => order.value?.available_free_skus)

  return {
    orderId,
    initOrder,
    // ORDER DATA
    order,
    soldOutCompareData,
    limitedCompareData,
    priceChangedCompareData,
    removeFromSoldOutCompareData,
    removeFromLimitedCompareData,
    // SINTACTIC SUGAR TO ACCESS ORDER DATA
    allOrderShipments,
    lineItems,
    freeGiftPromotions,
    promotionGifts,
    giftCards,
    shipments,
    adjustments,
    subtotalAmountFloatWithoutFreeGifts,
    discountAmountFloatWithoutFreeGifts,
    freeGiftPromotionsAmountFloat,
    couponLineItems,
    promoLineItems,
    // LOADING UTILS
    loading,
    // CREATE, UPDATE, FETCH METHODS
    createOrder,
    updateOrder,
    updateOrderMetadata,
    fetchOrder,
    // CHECK METHODS
    checkEditable,
    // LINE ITEMS
    addLineItem,
    removeLineItem,
    removeLineItemBySku,
    // QUANTITY
    updateQuantity,
    updateQuantityBySku,
    increaseQuantity,
    decreaseQuantity,
    isInOrder,
    //
    initCompare,
  }
}

import type {
  Cookie,
  SelectedCookie,
} from '@design-system/components/Cookie/CookiebannerCenter.type'

type OneTrustCookie = {
  OptanonGroupId: string
  GroupName: string
  GroupDescription: string
  Status: 'always active' | 'active' | 'inactive'
  Cookies: any[]
}

type OneTrust = {
  GetDomainData: () => {
    Groups: OneTrustCookie[]
  }
  RejectAll: () => void
  AllowAll: () => void
  IsAlertBoxClosedAndValid: () => boolean
  changeLanguage: (language: string) => void
  UpdateConsent: (type: string, cookies: string) => void
}

declare global {
  interface Window {
    OneTrust: OneTrust
  }
}

export const useOneTrustCookies = () => {
  // for federated app support
  const isLoaded = useState('is_loaded_cookie', () => false)
  const { eventBus } = useFederatedEventBus()
  const isFederated = useRuntimeConfig().public.isFederated
  const { countryLocale } = useCountryLocale()
  const { country } = useRouteHelper()

  const notLoadCookieBanner = [
    'en-au',
    'fr-be',
    'en-ca',
    'es-cl',
    'en-hk',
    'en-id',
    'en-il',
    'ja-jp',
    'ko-kr',
    'es-mx',
    'en-my',
    'en-nz',
    'en-ph',
    'en-sg',
    'en-th',
    'en-tr',
    'en-tw',
    'en-ua',
    'en-wx',
    'en-za',
  ]

  const notShowCookieBanner = ['en-us', 'es-us']

  const globalScript = {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    'data-domain-script': '01919d3c-be13-74ce-a59e-ed8763aa05ee',
    async: true,
  }

  const unitedStatesSpecificScript = {
    src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    'data-domain-script': '0192b849-42e6-7972-b5cf-8308303c610c',
    async: true,
  }

  const { proxy: _proxy, load: loadCookie } = useScript<OneTrust>(
    country.toLowerCase() === 'us' ? unitedStatesSpecificScript : globalScript,
    {
      trigger: 'manual',
      use() {
        return window.OneTrust
      },
    }
  )

  let proxy = isFederated ? window.OneTrust : _proxy
  const federatedFix = () => {
    if (isFederated || typeof window.OneTrust !== 'undefined') {
      proxy = window.OneTrust
    }
  }

  const { language } = useRouteHelper()
  const { openDialog: openCookieBanner } = useDialog('modal-cookie-banner')
  const cookies = useState<Cookie[]>('ONE_TRUST_COOKIES', () => [])

  const allowedCookies = computed<SelectedCookie[]>(() =>
    cookies.value.map(cookie => ({
      id: cookie.id,
      selected: cookie.selected ?? false,
    }))
  )

  const checkSavedConsent = () => {
    const cookieValue = useCookie('OptanonConsent').value
    if (cookieValue) {
      const cookieParams = new URLSearchParams(cookieValue)

      const groups = cookieParams.get('groups')

      if (groups) {
        const groupPairs = groups.split(',').map(group => {
          const [id, value] = group.split(':')
          return { id, value: parseInt(value) }
        })

        return groupPairs
      }
    }
  }

  const showCookieBanner = () => {}

  const loadScript = () => {
    if (
      countryLocale.value &&
      notLoadCookieBanner.findIndex(
        countryLocaleBanner => countryLocaleBanner === countryLocale.value
      ) === -1
    ) {
      loadCookie()
      const intervalCookie = setInterval(() => {
        if (window.OneTrust) {
          isLoaded.value = true
          if (isFederated) {
            proxy = window.OneTrust
          }
          const oneTrustCookie = window.OneTrust.GetDomainData().Groups.filter(
            data => data.Cookies.length
          )
          cookies.value = oneTrustCookie.map(cookie => ({
            id: cookie.OptanonGroupId,
            title: cookie.GroupName,
            text: cookie.GroupDescription,
            offButton: cookie.Status !== 'always active',
            defaultSelected:
              cookie.Status === 'always active' ? true : undefined,
            selected: cookie.Status === 'always active' ? true : undefined,
          }))
          window.OneTrust.changeLanguage(language)
          if (!window.OneTrust.IsAlertBoxClosedAndValid()) {
            const mergeArray = [...notLoadCookieBanner, ...notShowCookieBanner]
            if (
              mergeArray.find(
                countryLocaleNotShow =>
                  countryLocaleNotShow === countryLocale.value
              )
            ) {
              clearInterval(intervalCookie)
              return
            }
            openCookieBanner()
          } else {
            eventBus?.$emit('privacyAcceptanceEvent', true)
            const savedCookies = checkSavedConsent()
            if (savedCookies?.length) {
              savedCookies.forEach(cookieSaved => {
                const item = cookies.value.find(
                  cookie => cookie.id === cookieSaved.id
                )
                item &&
                  item.offButton === true &&
                  (item.selected = cookieSaved.value === 1)
              })
            }
          }
          clearInterval(intervalCookie)
        }
      }, 100)
      setTimeout(() => {
        clearInterval(intervalCookie)
      }, 10000)
    }
  }

  const toggleCookie = (id: string, selected: boolean) => {
    cookies.value = cookies.value.map(cookie =>
      cookie.id === id ? { ...cookie, selected } : cookie
    )
  }

  const save = () => {
    const oneTrustConsentCookies = allowedCookies.value
      .map(item => `${item.id}:${item.selected ? 1 : 0}`)
      .join(',')

    federatedFix()
    proxy.UpdateConsent('Category', oneTrustConsentCookies)
  }

  const acceptAll = () => {
    cookies.value.forEach(cookie => (cookie.selected = true))
    federatedFix()
    eventBus?.$emit('privacyAcceptanceEvent', true)
    proxy.AllowAll()
  }

  const rejectAll = () => {
    cookies.value.forEach(cookie => {
      if (cookie.offButton) {
        cookie.selected = false
      }
    })
    federatedFix()
    eventBus?.$emit('privacyAcceptanceEvent', false)
    proxy.RejectAll()
  }

  return {
    cookies,
    showCookieBanner,
    rejectAll,
    acceptAll,
    changeCookieLanguage: proxy?.changeLanguage ?? null,
    toggleCookie,
    save,
    isLoaded,
    loadCookie,
    notLoadCookieBanner,
    loadScript,
  }
}

import type {
  GASetupDatalayer,
  GASetupDatalayerParamItems,
  GASetupDatalayerParamPage,
  GASetupDatalayerParamUser,
  GASetupDatalayerParamWishlist,
} from '@integration-layer/schemas/GAEvents/dataLayerSetup'

export const useResetParams = () => {
  const defaultValues: Omit<GASetupDatalayer, 'event'> = {
    paramGlobal: {
      environment: 'development',
      device: 'm',
      current_shipping: '',
      website_language: '',
      selected_currency: '',
      localDate: '',
      local_hour_minute: '',
      local_parts_of_day: 'morning',
      local_office_hours: 'office',
      timestamp: '',
    },
    paramUser: {
      userLogged: 0,
      userID: '',
      registrationDate: '',
      userType: '',
      userAge: '',
      userGender: 'unknown',
      cluster_age: '',
      SHA256_hashed_userEmail: '',
      registeredNewsletter: 0,
      userName: '',
      userPhone: '',
      userSurname: '',
    },
    paramPage: {
      page_status_code: 200,
      sub_page_type: '',
      department: '',
      brand: 'armani_group',
      gender: 'E',
      page_type: '',
    },
    paramItems: {
      itemOutOfStock: 0,
      storeAvailability: 0,
      item_category_level_1: '',
      item_category_level_2: '',
      shop_category: 'category',
      product_listSize: 0,
      product_listtype: 'grid432',
    },
    paramCart: {
      cartID: '',
      cart_type: 'mono_brand',
      emporio_armani_cart: 0,
      armani_exchange_cart: 0,
      ea7_cart: 0,
      giorgio_armani_cart: 0,
      client_service_cart: 0,
      cart_products: 0,
      cartTotalValue: '0.00',
    },
    paramWishlist: {
      armani_exchange_wishlist: 0,
      ea7_wishlist: 0,
      emporio_armani_wishlist: 0,
      giorgio_armani_wishlist: 0,
      wishlist_products: 0,
      wishlistTotalValue: 0,
    },
    paramSales: {
      checkout_type: 0,
      payment_type: '',
      delivery_type: '',
    },
    paramStore: {
      store: '',
      city_store: '',
    },
  }
  // Map defaults value in case some fields are missing from entities
  const mapWithDefaults = <T extends Record<string, unknown>>(
    obj: T,
    defaults: T
  ): T => {
    if (obj === undefined) {
      return defaults as T
    }
    const result: Partial<T> = { ...defaults }
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        result[key] = obj[key] ?? defaults[key]
      }
    }
    return result as T
  }

  const setParamUser = (
    payload: GASetupDatalayerParamUser
  ): GASetupDatalayerParamUser =>
    mapWithDefaults(payload, defaultValues.paramUser)
  const setParamWishlist = (
    payload: GASetupDatalayerParamWishlist
  ): GASetupDatalayerParamWishlist =>
    mapWithDefaults(payload, defaultValues.paramWishlist)
  const setParamItems = (
    payload: GASetupDatalayerParamItems
  ): GASetupDatalayerParamItems =>
    mapWithDefaults(payload, defaultValues.paramItems)
  const setParamPages = (
    payload: GASetupDatalayerParamPage
  ): GASetupDatalayerParamPage =>
    mapWithDefaults(payload, defaultValues.paramPage)

  return {
    defaultValues,
    setParamUser,
    setParamWishlist,
    setParamItems,
    setParamPages,
  }
}

import type { ProductCardHorizontalProps } from '@design-system/components/UI/UIProductCardHorizontal.props'
import type { CartFeedbackAddToCartProps } from '@design-system/components/Cart/CartFeedbackAddToCart.props'
import type { LineItem } from '@commercelayer/sdk'
import defu from 'defu'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

export const useAddToCart = () => {
  const { addToCart, lineItems, lastAdded } = useCart()
  const { openDialog, closeDialog } = useDialog()
  const { formatPriceByLocale } = useFormatPrice()

  const sizeSelectorSideSlideId = SIDEBAR_IDS.pdpEcommerceBlockSizeSelector

  const isLoading = useState('isLoading', () => false)

  const error = ref(false)
  // TODO: (tracked #1193) type
  const addToCartPayload = useState<null | any>('addToCartPayload', () => null)
  const lastAddedToCart = useState<
    null | (ProductCardHorizontalProps & CartFeedbackAddToCartProps)
  >('lastAddedToCart', () => null)

  const getLastAddedToCart = (
    lineItem: LineItem
  ): ProductCardHorizontalProps => ({
    id: lineItem.id,
    info: {
      name: lineItem.name!,
      price: lineItem.unit_amount_float!,
      oldPrice:
        lineItem.compare_at_amount_float !== lineItem.unit_amount_float
          ? lineItem.compare_at_amount_float!
          : 0,
      percentageDiscount: percentageDiscount(
        lineItem.total_amount_float!,
        lineItem.total_amount_float! + lineItem.discount_float!
      ),
      color: lineItem.metadata?.color,
      size: lineItem.metadata?.size,
    },
    path: '#',
    available:
      lastAdded.value?.item.inventory?.available! &&
      lastAdded.value?.item.inventory?.quantity > 0,
    quantity: lineItem.quantity,
    metadata: lineItem.metadata?.website,
    image:
      lineItem.image_url ?? '/images/product-card-horizontal-placeholder.png',
    skuCode: lineItem.sku_code!,
  })

  const { currentWebsite } = useWebsite()

  const addToCart_ = async () => {
    if (!addToCartPayload.value) {
      openDialog(sizeSelectorSideSlideId)
      return
    }

    try {
      isLoading.value = true
      error.value = false
      const isAlreadyAdded = lineItems.value.some(
        ({ sku_code }) => sku_code === addToCartPayload.value.sku_code
      )
      const { quantity, ...attributes } = addToCartPayload.value
      const lineItem = await addToCart(
        defu(attributes, {
          metadata: { website: currentWebsite.value },
        }),
        quantity
      )

      if (lineItem) {
        lastAddedToCart.value = {
          ...getLastAddedToCart(lineItem),
          isAlreadyAdded,
        }
        openDialog(SIDEBAR_IDS.cartFeedbackAddToCart)
      }
    } catch (err) {
      error.value = true
    } finally {
      isLoading.value = false
      closeDialog(sizeSelectorSideSlideId)
    }
  }

  return {
    addToCartPayload,
    addToCart: addToCart_,
    isLoading,
    error,
    lastAddedToCart,
  }
}

import type { Split } from 'type-fest'

export const useDateOfBirth = () => {
  const { country } = useRouteHelper()

  const maskConfig = computed(() => {
    return country === 'us' || country === 'ca'
      ? 'month/day/year'
      : 'day/month/year'
  })

  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate)
    const dateObj = {
      day: date.getUTCDate(),
      month: date.getUTCMonth() + 1,
      year: date.getUTCFullYear(),
    }
    type MaskConfigParts = Split<typeof maskConfig.value, '/'>
    const maskConfigParts = maskConfig.value.split('/') as MaskConfigParts
    return maskConfigParts
      .map(part => dateObj[part].toString().padStart(2, '0'))
      .join('/')
  }

  const encodeDate = (formattedDate: string, country: string) => {
    const encodingMask =
      country === 'us' || country === 'ca' ? 'month/day/year' : 'day/month/year'

    // Validate the input date format based on maskConfig
    const expectedFormat = /^\d{2}\/\d{2}\/\d{4}$/

    if (!expectedFormat.test(formattedDate)) {
      throw new Error(
        `Invalid date format. Expected format: ${encodingMask.replace(/\//g, '/')}`
      )
    }

    const maskConfigParts = encodingMask.split('/')

    const formattedDateParts = formattedDate.split('/').map(part => {
      const parsedPart = parseInt(part, 10)
      if (isNaN(parsedPart)) {
        throw new Error(`Invalid date part: ${part}`)
      }
      return parsedPart
    })

    const dateObj = maskConfigParts.reduce(
      (dateObj, part, i) => ({
        ...dateObj,
        [part]: formattedDateParts[i],
      }),
      {} as Record<string, number>
    )

    // Ensure all parts of the date are present
    if (
      dateObj.year === undefined ||
      dateObj.month === undefined ||
      dateObj.day === undefined
    ) {
      throw new Error('Incomplete date parts')
    }

    // Construct the date object correctly
    const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day, 12)
    return date.toISOString()
  }

  return {
    maskConfig,
    formatDate,
    encodeDate,
  }
}

// @ts-ignore
export default defineNuxtPlugin(async nuxtApp => {
  if (useRuntimeConfig().public.isFederated) return

  if (!nuxtApp.payload.serverRendered) {
    await useUserSession().fetch()
  } else if (
    Boolean(nuxtApp.payload.prerenderedAt) ||
    Boolean(nuxtApp.payload.isCached)
  ) {
    await useUserSession().fetch()
  }
  const { cl } = useCL()

  return {
    provide: {
      cl,
    },
  }
})

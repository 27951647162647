import { z } from 'zod'
import { registrationFormSchema } from '@integration-layer/schemas/userSchemas'
import type { Brand } from '@integration-layer/index'

export const useRegistration = () => {
  const { emailExists } = useAuth0()
  // REGISTRATION FORM IDs
  const REGISTRATION_FORM_CODE_ID = 'registration-form-code-id'
  const REGISTRATION_FORM_PERSONAL_INFO_ID =
    'registration-form-personal-info-id'

  // DA RIVEDERE
  const { signupPage } = useRouteHelper()
  const { currentWebsite, isBrand } = useWebsite()
  const route = useRoute()
  const { formatDate, encodeDate } = useDateOfBirth()
  const config = useAppConfig()
  const country = config.currentCountry as string
  const language = config.currentLanguage

  // REGISTRATION STATE
  const REGISTRATION_SUCCESSFUL = 'registration-successful'
  const REGISTRATION_USER = 'registrationUser'

  const brandMapping: { [key: string]: string } = brandAcronymMap

  const brandPreference = computed(() => {
    if (isBrand(currentWebsite.value)) {
      return [brandMapping[currentWebsite.value] || currentWebsite.value]
    } else {
      return ['GA', 'EA', 'E7', 'AX']
    }
  })

  const salesforceId = useState('user_registration_salesforce_ID', () => '')
  const isOtpValid = useState<boolean>(
    'use_registration_otp_validity',
    () => false
  )

  const registrationUser = useState<RegistrationUser>(
    REGISTRATION_USER,
    () => ({
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      gender: undefined,
      date_of_birth: '',
      prefix: '',
      phone_number: '',
      profiling_consent: undefined,
      channels_preferences: [],
      marketing_authorization: undefined,
      brand_preferences: brandPreference.value ?? [],
      verificationCode: '',
      country: country,
      language: language,
    })
  )

  // on otp validity, fetch or create a CRM account
  const sfIdRequested = useState('requested_sfId', () => false)

  watch(registrationUser, (n, o) => {
    if (n.email !== o.email) {
      sfIdRequested.value = false
    }
  })
  watch(
    isOtpValid,
    async (isValid, oldIsValid) => {
      if (isValid && isValid !== oldIsValid && !sfIdRequested.value) {
        sfIdRequested.value = true

        const { salesforceId: sfId, user } = await $fetch(
          '/api/auth0/getSfUser',
          {
            method: 'POST',
            body: {
              email: registrationUser.value.email,
            },
          }
        )
        salesforceId.value = sfId ?? ''
        if (user && typeof user !== 'boolean') {
          if (user.gender) registrationUser.value.gender = user.gender
          if (user.first_name)
            registrationUser.value.first_name = user.first_name
          if (user.last_name) registrationUser.value.last_name = user.last_name
          if (user.date_of_birth) {
            const formattedDate = formatDate(user.date_of_birth)
            registrationUser.value.date_of_birth = formattedDate
          }
          if (user.phone_number)
            registrationUser.value.phone_number = user.phone_number
          if (user.prefix) registrationUser.value.prefix = user.prefix
          if (user.marketing_authorization !== undefined) {
            // Explicit check for undefined to allow false values
            registrationUser.value.marketing_authorization =
              user.marketing_authorization
          }
          if (user.profiling_consent !== undefined) {
            registrationUser.value.profiling_consent = user.profiling_consent
          }
          if (user.channels_preferences) {
            registrationUser.value.channels_preferences =
              user.channels_preferences
          }
        }
      }
    },
    { immediate: true }
  )

  const registrationSuccessful = useState<boolean>(
    REGISTRATION_SUCCESSFUL,
    () => false
  )

  const cleanRegistrationData = () => {
    registrationUser.value = {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      gender: undefined,
      date_of_birth: '',
      prefix: '',
      phone_number: '',
      profiling_consent: undefined,
      channels_preferences: [],
      marketing_authorization: undefined,
      brand_preferences: [],
      verificationCode: '',
      country: country,
      language: language,
    }
  }

  const registerUser = async () => {
    const user = registrationUser.value
    // if I dont have a salesforce ID ad this point (for whichever reason, or maybe because I'm in cart page)
    // I ask for one
    if (!salesforceId.value && !salesforceId.value.length) {
      const { salesforceId: sfId } = await $fetch('/api/auth0/getSfUser', {
        method: 'POST',
        body: {
          email: registrationUser.value.email,
        },
      })
      salesforceId.value = sfId ?? ''
    }

    await $fetch('/api/auth0/registerUser', {
      method: 'POST',
      body: {
        salesforceId: salesforceId.value,
        email: user.email,
        password: user.password,
        brandPreference: brandPreference.value as string[],
        gender: user.gender ?? 'unknown',
        prefix: user.prefix ?? '',
        lastName: user.last_name,
        firstName: user.first_name,
        dateOfBirth: encodeDate(user.date_of_birth, country),
        phoneNumber: user.phone_number
          ? user.phone_number.toString()
          : undefined,
        channelsPreference: user.channels_preferences,
        contactAuthorization: user.contact_authorization ?? false,
        marketingAuthorization: user.marketing_authorization ?? false,
        country: country ?? '',
        language: language,
        profilingConsent: user.profiling_consent ?? false,
      } satisfies z.infer<typeof registrationFormSchema>,
    })
      .catch(e => {
        throw e
      })
      .then(() => {
        registrationSuccessful.value = true
      })
  }

  const userAlreadyOnSf = async () => {
    const { sfExists } = await emailExists(registrationUser.value.email)
    return sfExists
  }

  const isSignupPage = route.fullPath.includes(signupPage)

  return {
    REGISTRATION_FORM_CODE_ID,
    REGISTRATION_FORM_PERSONAL_INFO_ID,
    registrationSuccessful,
    cleanRegistrationData,
    registrationUser,
    brandPreference,
    registerUser,
    isOtpValid,
    salesforceId,
    isSignupPage,
    userAlreadyOnSf,
  }
}

import type { Auth, Token } from '@integration-layer/configs/commercelayer'
import { isTokenValid } from '@integration-layer/configs/commercelayer'
import { StorageSerializers } from '@vueuse/core'

export const useClAuth = (): Auth => {
  const config = useRuntimeConfig()
  const configs = useConfigs()
  const organization = config.public.commercelayer.organization
  const market = configs.value.cl_market_code!

  const inMemoryTokenPromisePerMarket: Record<string, Promise<Token>> = {}

  const storage = process.client
    ? useLocalStorage<Token>(`commercelayer:guestToken:${market}`, null, {
        serializer: StorageSerializers.object,
      })
    : null

  const getToken = async () => {
    const tokenPromise = inMemoryTokenPromisePerMarket[market]
    const token = (await tokenPromise) ?? storage?.value

    if (token && isTokenValid(token)) return token.accessToken

    return await refreshToken()
  }

  const refreshToken = async () => {
    // TODO: (tracked #1195) type should be inferred
    // @ts-ignore
    const tokenPromise = $fetch<Token>(`/api/getCLGuestToken/${market}`)
    // @ts-ignore
    inMemoryTokenPromisePerMarket[market] = tokenPromise
    const newToken = await tokenPromise

    if (storage) {
      // @ts-ignore
      storage.value = newToken
    }

    return newToken.accessToken
  }

  return {
    organization,
    getToken,
    refreshToken,
  }
}

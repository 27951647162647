export default defineNuxtPlugin({
  name: 'datadog',
  parallel: true,
  async setup() {
    if (useRuntimeConfig().public.isFederated) return

    if (import.meta.dev) return
    const { datadogRum } = await import('@datadog/browser-rum')
    const config = useRuntimeConfig()
    datadogRum.init({
      applicationId: config.public.datadog.ddApplicationId,
      clientToken: config.public.datadog.ddClientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: config.public.datadog.ddService,
      env: config.public.datadog.ddEnv,
      version: config.public.app_version as string,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 100,
      startSessionReplayRecordingManually: false,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()
  },
})

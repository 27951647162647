import { withoutTrailingSlash, withTrailingSlash } from 'ufo'

export const useRouteHelper = () => {
  const { currentWebsite } = useWebsite()
  const app = useNuxtApp()
  const appConfig = useAppConfig()
  const language = appConfig.currentLanguage
  const country = appConfig.currentCountry
  const { trailingSlash } = useRuntimeConfig().public

  const countryAndLanguage = `${language}-${country}`
  const localizedPath = (path: string) => `/${countryAndLanguage}${path}`
  const getCanonicalUrl = (url: string) =>
    trailingSlash
      ? withTrailingSlash(url, true)
      : withoutTrailingSlash(url, true)

  const homePath = `/${countryAndLanguage}`
  // create a computed homeBrandPath that should check if the path is like this /${countryAndLanguage}/{currentWebsite}
  const homeBrandPath = computed(() => {
    if (currentWebsite.value !== 'global') {
      return localizedPath(`/${currentWebsite.value}/experience`)
    }
    return homePath
  })

  // commerce
  const cartUrl = localizedPath(`/cart`)
  const checkoutUrl = localizedPath(`/checkout`)
  const thankYouUrl = (orderId: string) =>
    localizedPath(`/thank-you/${orderId}`)

  // guest
  const guestWishlist = localizedPath(`/wishlist`)

  const orderDetailsUrlGuest = ({ email, id }: { email: string; id: string }) =>
    localizedPath(`/track/orders/${email}/${id}`)

  const returnOrderUrlGuest = (params: { email: string; id: string }) =>
    `${orderDetailsUrlGuest(params)}/return`

  const returnOrderThankYouUrlGuest = (params: { email: string; id: string }) =>
    `${orderDetailsUrlGuest(params)}/thank-you`

  // auth
  const signupPage = localizedPath(`/signup`)
  const registerSuccessful = localizedPath(`/register-successful`)
  const resetPasswordUrl = localizedPath(`/reset-password`)

  // logged
  const myAccount = localizedPath(`/my-account`)

  const orders = `${myAccount}/orders`
  const orderDetailsUrl = (orderId: string) => `${orders}/${orderId}`

  const returnOrderUrl = (orderId: string) =>
    `${orderDetailsUrl(orderId)}/return`

  const returnOrderThankYouUrl = (orderId: string) =>
    `${orderDetailsUrl(orderId)}/thank-you`

  const wishlist = `${myAccount}/wishlist`

  const forYou = `${myAccount}/for-you`

  const profile = `${myAccount}/profile`
  const accountDetails = `${profile}/details`
  const address = `${profile}/address`
  const contactPreferences = `${profile}/contact`
  const deleteAccount = `${profile}/delete`

  // generic
  const help = localizedPath(`/help`)
  const helpSection = (section: string) => `${help}/${section}`
  const helpContactUs = helpSection(`contact-us`)

  const legal = localizedPath(`/legal`)
  const termsOfSale = `${legal}/terms-of-sale`
  const rightOfWithdrawal = `${legal}/right-of-withdrawal`
  const privacyPolicy = `${legal}/privacy-policy`
  const cookiePolicy = `${legal}/cookie-policy`
  const accessibility = `${legal}/accessibility`
  const accessibilityApp = `${legal}/apps-accessibility`
  const legalSection = (section: string) => `${legal}/${section}`
  const storeLocator = 'https://locations.armani.com/'

  const pdpPath = (
    algoliaProduct: {
      NameURL?:
        | {
            lang?: string | undefined
            name?: string | null | undefined
          }[]
        | undefined
      objectID: string
      Brand: string
    },
    addTrailingSlash = true,
    localize = true
  ) => {
    const path = getPdpPath(algoliaProduct, language, addTrailingSlash)
    return localize ? localizedPath(path) : path
  }

  const isPdp = computed<boolean>(() => !!app.payload?.['is_product'])
  const isPlp = computed<boolean>(
    () =>
      app.payload &&
      app.payload?.amplience_page_type === 'https://armani.com/plp-page'
  )
  const isHome = computed<boolean>(
    () =>
      app.payload &&
      app?.payload?.amplience_page_type ===
        'https://armani.com/crossbrand-homepage-newest'
  )
  const isBrandHome = computed<boolean>(
    () =>
      app.payload &&
      app?.payload?.amplience_page_type === 'https://armani.com/brand-homepage'
  )

  const isEditorialPage = computed<boolean>(
    () =>
      app.payload &&
      app?.payload?.amplience_page_type === 'https://armani.com/editorial-page'
  )

  return {
    signupPage,
    cartUrl,
    checkoutUrl,
    resetPasswordUrl,
    myAccount,
    profile,
    orders,
    wishlist,
    forYou,
    accountDetails,
    address,
    contactPreferences,
    deleteAccount,
    guestWishlist,
    getCanonicalUrl,
    country: (country as string) ?? 'us',
    language: language ?? 'EN',
    countryAndLanguage: countryAndLanguage.substring(0, 5),
    thankYouUrl,
    homePath,
    homeBrandPath,
    orderDetailsUrl,
    orderDetailsUrlGuest,
    returnOrderUrl,
    returnOrderThankYouUrl,
    returnOrderUrlGuest,
    returnOrderThankYouUrlGuest,
    registerSuccessful,
    help,
    helpSection,
    helpContactUs,
    isHome,
    legal,
    termsOfSale,
    rightOfWithdrawal,
    privacyPolicy,
    cookiePolicy,
    accessibility,
    accessibilityApp,
    legalSection,
    pdpPath,
    isPdp,
    isPlp,
    isBrandHome,
    localizedPath,
    storeLocator,
    isEditorialPage,
  }
}

<script setup lang="ts">
import { useDatalayerSetup } from '@integration-layer/composables/useGAEvent/useDatalayerSetup/useDatalayerSetup'
import { useParamPage } from '@integration-layer/composables/useGAEvent/useDatalayerSetup/useParamPage'

const app = useNuxtApp()

const { deferredBrandTheme } = await useTheme()

const { getLocale } = useI18n()
const locale = getLocale()
const route = useRoute()
const { initCart, initCompare } = useCart()
const { loadScript } = useOneTrustCookies()
const { checkoutUrl, cartUrl } = useRouteHelper()

const { mergeWishlists, refreshWishlistOnTabChange } = await useWishlist()
const { addWishlistTabChangeListener, removeWishlistTabChangeListener } =
  refreshWishlistOnTabChange()

const isCheckoutPage = computed(() => route.path.includes(checkoutUrl))
const isCartPage = computed(() => route.path.includes(cartUrl))
const { checkScript } = useXgen()

let isDatalayerSetupInitialized = false

const { isIos } = useDevice()
const mounted = ref(false)

onMounted(async () => {
  await initCart()
  // randomUUID if present, random 10 digits number if possible
  useRiskified(self?.crypto?.randomUUID?.() ?? Math.floor(Math.random() * 1e10))
  useSizebay()
  checkScript()
  addWishlistTabChangeListener()
  mergeWishlists()
  if (isCheckoutPage.value || isCartPage.value) {
    initCompare()
  }
  useDatalayerSetup()

  mounted.value = true
})

watch(mounted, () => {
  if (process.client && mounted.value) {
    loadScript()
  }
})

onBeforeUnmount(() => {
  removeWishlistTabChangeListener()
})

useHead({
  htmlAttrs: {
    lang: locale.split('-')[0],
  },
  meta: [
    {
      name: 'viewport',
      content: isIos
        ? 'width=device-width, initial-scale=1, maximum-scale=1'
        : 'width=device-width, initial-scale=1',
    },
  ],
})

// Trigger send event on route change
useScriptEventPage(async () => {
  // Page is a generic page only if is not amplience or product-based
  if (!(app.payload['is_product'] || !!app.payload['amplience_page_type'])) {
    useParamPage('genericPage')
  }
  if (isDatalayerSetupInitialized) {
    useDatalayerSetup()
  }
  isDatalayerSetupInitialized = true
})
// Use SSR-safe IDs for Headless UI
// https://github.com/P4sca1/nuxt-headlessui#quick-setup
provideHeadlessUseId(() => useId())
</script>

<template>
  <div>
    <UtilsSkipLink />
    <NuxtLoadingIndicator
      class="bg-background-primary"
      :class="`theme-${deferredBrandTheme}`"
      :color="false"
      :height="2"
    />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AtomsLoaderArmani />
    <ClientOnly>
      <CookieBanner />
    </ClientOnly>
  </div>
</template>

<style lang="scss">
#onetrust-consent-sdk {
  display: none;
}
</style>
